.anchor-wrapper {
  position: relative;
}
code .anchor-wrapper,
kbd .anchor-wrapper {
  margin: -2px -4px;
  padding: 2px 4px;
}
.anchor-link {
  display: block;
  font-size: 1em;
  min-width: 1.25em;
  position: absolute;
}
h1 .anchor-link,
h2 .anchor-link,
h3 .anchor-link,
h4 .anchor-link,
h5 .anchor-link,
h6 .anchor-link {
  font-size: 0.75em;
}
.anchor-link.fade a {
  transition: color 300ms ease-in-out, opacity 300ms ease-in-out;
}
.anchor-link a {
  opacity: 0.35;
  filter: alpha(opacity=35);
  color: #333333;
  padding: 0.25em;
  text-decoration: none;
}
.anchor-link a:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  color: #337ab7;
  text-decoration: none;
}
/*# sourceMappingURL=bootstrap-anchor.css.map */